import React, { useEffect, useState } from "react";
import {
  BsClock,
  BsCpuFill,
  BsCurrencyDollar,
  BsMemory,
  BsTelephoneInboundFill,
} from "react-icons/bs";
import { GoDotFill } from "react-icons/go";

import { Stacked, Pie, Button, SparkLine, Header } from "../components";
import {
  earningData,
  SparklineAreaData,
  ecomPieChartData,
} from "../data/dummy";
import { useStatContext } from "../contexts/ContextProvider";
import {
  BiCalendar,
  BiCoin,
  BiCoinStack,
  BiCreditCard,
  BiDollar,
  BiDownload,
  BiEdit,
  BiMemoryCard,
  BiPause,
  BiPlay,
  BiUpload,
} from "react-icons/bi";
import {
  GrClose,
  GrMemory,
  GrNetwork,
  GrStatusInfo,
  GrStorage,
  GrTransaction,
} from "react-icons/gr";
import { SiSpeedtest } from "react-icons/si";
import { FiCpu } from "react-icons/fi";
import { MdClose, MdMemory } from "react-icons/md";
import { TiTick, TiTicket } from "react-icons/ti";
import Modal from "../components/Modal";
import { GiConfirmed, GiPayMoney } from "react-icons/gi";
import {
  fetchManagerTransactions,
  fetchPendingCryptoTransactions,
  sandy,
  validateCryptoTransaction,
  validateFirstUserTransaction,
  validateTransaction,
} from "../utils/HttpUtils";

const Transactions = () => {
  const { currentColor, fetchOverview, messages, setCurrentToast } =
    useStatContext();

  const [rialTransactions, setRialTransactions] = useState(undefined);
  const [cryptoTransactions, setCryptoTransactions] = useState(undefined);
  const [transactionsMode, setTransactionsMode] = useState("rials");
  const [selectedTransaction, setSelectedTransaction] = useState({});

  const fetchUserRialTransactionsFunc = async () => {
    try {
      const result = await fetchManagerTransactions();
      console.log(result);
      setRialTransactions((prev) => result);
    } catch (error) {
      console.log(error.message);
    }
  };

  const fetchUserCryptoTransactionsFunc = async () => {
    try {
      const result = await fetchPendingCryptoTransactions();
      setCryptoTransactions((prev) => result);
    } catch (error) {
      console.log(error.message);
    }
  };

  const [rialTransactionEditModal, setRialTransactionEditModal] =
    useState(false);
  const [progressing, setProgressing] = useState(false);

  useEffect(() => {
    fetchUserRialTransactionsFunc();
    fetchUserCryptoTransactionsFunc();
    // fetchOverals();

    // setInterval(fetchOverals, 3000);
    return () => {
      // clearInterval(fetchOverals);
    };
  }, []);

  return (
    <>
      <div
        className="relative mt-12 flex-col justify-center items-center
    m-10 p-5
    bg-black"
      >
        <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
        <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
        <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
        <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
        <Header category={messages.financial} title={messages.transactions} />
        {rialTransactions || cryptoTransactions ? (
          <div
            className="flex flex-col gap-2 justify-start
      my-5 mx-4 p-1"
          >
            <div className="flex justify-start">
              <button
                onClick={() => {
                  setTransactionsMode("crypto");
                }}
                className={`${
                  transactionsMode === "crypto"
                    ? "bg-[#1ffea9]"
                    : "bg-black text-[#1ffea9]"
                } border-1 border-[#1ffea9]
           p-2 hover:bg-[#1ffea9] hover:text-black`}
              >
                {messages.transactions_crypto_button}
              </button>
              <button
                onClick={() => {
                  setTransactionsMode("rials");
                }}
                className={`${
                  transactionsMode === "rials"
                    ? "bg-[#1ffea9]"
                    : "bg-black text-[#1ffea9]"
                } border-1 border-[#1ffea9]
            p-2 hover:bg-[#1ffea9] hover:text-black`}
              >
                {messages.transactions_rials_button}
              </button>
            </div>
            {transactionsMode === "crypto" ? (
              <>
                {cryptoTransactions && cryptoTransactions.length == 0 ? (
                  <div className="flex justify-center items-center">
                    <p
                      className="text-[#1ffea9] bg-radial-gradient
                         border-1 border-[#1ffea9] px-4 py-2"
                    >
                      {messages.no_crypto_transactions}
                    </p>
                  </div>
                ) : (
                  <div className="w-full flex gap-2 flex-wrap">
                    {cryptoTransactions &&
                      cryptoTransactions.map((cryptotransaction, index) => {
                        return (
                          <div
                            key={cryptotransaction.tid}
                            className="relative flex flex-col border-1
             w-64
          border-[#1ffea9] py-8 px-6 gap-2
           hover:bg-radial-gradient"
                          >
                            <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-2 top-2"></div>
                            <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-2 top-2"></div>
                            {cryptotransaction.tid ===
                              selectedTransaction.tid && (
                              <div
                                className="absolute end-3 top-3
              loader-small"
                              ></div>
                            )}

                            <div className="absolute left-0 bottom-0 flex">
                              <button
                                onClick={async () => {
                                  try {
                                    setSelectedTransaction(cryptotransaction);
                                    if (cryptotransaction.first_user) {
                                      let result =
                                        await validateFirstUserTransaction(
                                          cryptotransaction.tid,
                                          cryptotransaction.user_id,
                                          1
                                        );
                                    } else {
                                      let result = await validateTransaction(
                                        cryptotransaction.tid,
                                        cryptotransaction.user_id,
                                        1
                                      );
                                    }
                                    await new Promise((r) =>
                                      setTimeout(r, 2000)
                                    );
                                  } catch (e) {
                                    setCurrentToast({
                                      isToasted: true,
                                      text: error.message,
                                      color: "red-500",
                                    });
                                  }
                                  fetchUserCryptoTransactionsFunc();
                                  setSelectedTransaction({});
                                  setCryptoTransactions(undefined);
                                }}
                                className=" bg-[#1ffea9] h-6 w-6 text-black
                 flex justify-center items-center"
                              >
                                <TiTick />
                              </button>
                              <button
                                onClick={async () => {
                                  try {
                                    setSelectedTransaction(cryptotransaction);
                                    if (cryptotransaction.first_user) {
                                      let result =
                                        await validateFirstUserTransaction(
                                          cryptotransaction.tid,
                                          cryptotransaction.user_id,
                                          2
                                        );
                                    } else {
                                      let result = await validateTransaction(
                                        cryptotransaction.tid,
                                        cryptotransaction.user_id,
                                        2
                                      );
                                    }
                                    await new Promise((r) =>
                                      setTimeout(r, 2000)
                                    );
                                  } catch (e) {
                                    setCurrentToast({
                                      isToasted: true,
                                      text: error.message,
                                      color: "red-500",
                                    });
                                  }
                                  fetchUserCryptoTransactionsFunc();
                                  setSelectedTransaction({});
                                  setCryptoTransactions(undefined);
                                }}
                                className=" bg-red-500 h-6 w-6 text-black ms-2
               flex justify-center items-center"
                              >
                                <MdClose />
                              </button>
                            </div>
                            <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-2 bottom-2"></div>
                            <div className="flex items-center flex-wrap">
                              <BiDollar className="text-[#1ffea9]" />
                              <span className="ms-2 text-[#1ffea9] text-sm">
                                {messages.amount} :
                              </span>
                              <span className="ms-2 text-white text-sm">
                                {cryptotransaction.amount.toFixed(3)} $
                              </span>
                            </div>
                            <div className="flex items-center flex-wrap">
                              <GrNetwork className="text-[#1ffea9]" />
                              <span className="ms-2 text-[#1ffea9] text-sm">
                                {messages.network} :
                              </span>
                              <span className="ms-2 text-white text-sm">
                                {cryptotransaction.method}
                              </span>
                            </div>
                            <div className="flex items-center flex-wrap">
                              <GrTransaction className="text-[#1ffea9]" />
                              <span className="ms-2 text-[#1ffea9] text-sm">
                                {messages.transaction_id} :
                              </span>
                              <span className="ms-2 text-white text-sm">
                                {cryptotransaction.crypto_transaction_id}
                              </span>
                            </div>
                            <div className="flex items-center flex-wrap">
                              <BiCalendar className="text-[#1ffea9]" />
                              <span className="ms-2 text-[#1ffea9] text-sm">
                                {messages.time} :
                              </span>
                              <span className="ms-2 text-white text-[12px]">
                                {cryptotransaction.tcreated
                                  .slice(0, 19)
                                  .replace("T", " ")}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </>
            ) : (
              <>
                {rialTransactions && rialTransactions.length == 0 ? (
                  <div className="flex justify-center items-center">
                    <p
                      className="text-[#1ffea9] bg-radial-gradient
                           border-1 border-[#1ffea9] px-4 py-2"
                    >
                      {messages.no_rials_transactions}
                    </p>
                  </div>
                ) : (
                  <div className="w-full flex gap-2 flex-wrap">
                    {rialTransactions &&
                      rialTransactions.map((rialtransaction, index) => {
                        return (
                          <div
                            key={rialtransaction.tid}
                            className="relative flex flex-col border-1
               w-64
            border-[#1ffea9] py-8 px-6 gap-2
             hover:bg-radial-gradient"
                          >
                            <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-2 top-2"></div>
                            <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-2 top-2"></div>
                            {rialtransaction.tid ===
                              selectedTransaction.tid && (
                              <div
                                className="absolute end-3 top-3
                loader-small"
                              ></div>
                            )}

                            <div className="absolute left-0 bottom-0 flex">
                              <button
                                onClick={async () => {
                                  try {
                                    setSelectedTransaction(rialtransaction);
                                    if (rialtransaction.first_user) {
                                      let result =
                                        await validateFirstUserTransaction(
                                          rialtransaction.tid,
                                          rialtransaction.user_id,
                                          1
                                        );
                                    } else {
                                      let result = await validateTransaction(
                                        rialtransaction.tid,
                                        rialtransaction.user_id,
                                        1
                                      );
                                    }

                                    await new Promise((r) =>
                                      setTimeout(r, 2000)
                                    );
                                  } catch (e) {
                                    setCurrentToast({
                                      isToasted: true,
                                      text: e.message,
                                      color: "red-500",
                                    });
                                  }
                                  fetchUserRialTransactionsFunc();
                                  setSelectedTransaction({});
                                  setRialTransactions(undefined);
                                }}
                                className=" bg-[#1ffea9] h-6 w-6 text-black
                   flex justify-center items-center"
                              >
                                <TiTick />
                              </button>
                              <button
                                onClick={async () => {
                                  try {
                                    setSelectedTransaction(rialtransaction);
                                    if (rialtransaction.first_user) {
                                      let result =
                                        await validateFirstUserTransaction(
                                          rialtransaction.tid,
                                          rialtransaction.user_id,
                                          2
                                        );
                                    } else {
                                      let result = await validateTransaction(
                                        rialtransaction.tid,
                                        rialtransaction.user_id,
                                        2
                                      );
                                    }
                                    await new Promise((r) =>
                                      setTimeout(r, 2000)
                                    );
                                  } catch (e) {
                                    setCurrentToast({
                                      isToasted: true,
                                      text: e.message,
                                      color: "red-500",
                                    });
                                  }
                                  fetchUserRialTransactionsFunc();
                                  setSelectedTransaction({});
                                  setRialTransactions(undefined);
                                }}
                                className=" bg-red-500 h-6 w-6 text-black ms-2
                 flex justify-center items-center"
                              >
                                <MdClose />
                              </button>
                            </div>
                            <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-2 bottom-2"></div>
                            <div className="flex items-center flex-wrap">
                              <BiCoin className="text-[#1ffea9]" />
                              <span className="ms-2 text-[#1ffea9] text-sm">
                                {messages.amount} :
                              </span>
                              <span className="ms-2 text-white text-sm">
                                {rialtransaction.amount.toFixed(3)} $
                              </span>
                            </div>
                            <div className="flex items-center flex-wrap">
                              <BiCoinStack className="text-[#1ffea9]" />
                              <span className="ms-2 text-[#1ffea9] text-sm">
                                {messages.rials} :
                              </span>
                              <span className="ms-2 text-white text-sm">
                                {rialtransaction.rials}
                              </span>
                              <span className="ms-2 text-white text-[12px]">
                                {messages.tomans}
                              </span>
                            </div>
                            <div className="flex items-center flex-wrap">
                              <BiCreditCard className="text-[#1ffea9]" />
                              <span className="ms-2 text-[#1ffea9] text-sm">
                                {messages.tx_last_4_digits} :
                              </span>
                              <span className="ms-2 text-white text-sm">
                                {rialtransaction.last_digits}
                              </span>
                            </div>
                            <div className="flex items-center flex-wrap">
                              <GrTransaction className="text-[#1ffea9]" />
                              <span className="ms-2 text-[#1ffea9] text-sm">
                                {messages.transaction_id} :
                              </span>
                              <span className="ms-2 text-white text-sm">
                                {rialtransaction.rial_transaction_id}
                              </span>
                            </div>
                            <div className="flex items-center flex-wrap">
                              <BiCalendar className="text-[#1ffea9]" />
                              <span className="ms-2 text-[#1ffea9] text-sm">
                                {messages.time} :
                              </span>
                              <span className="ms-2 text-white text-[12px]">
                                {rialtransaction.tcreated
                                  .slice(0, 19)
                                  .replace("T", " ")}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </>
            )}
          </div>
        ) : (
          <div className="flex justify-center items-center">
            <div className="loader"></div>
          </div>
        )}
      </div>
      <Modal
        open={rialTransactionEditModal}
        onClose={() => {
          setRialTransactionEditModal(false);
          setProgressing(false);
        }}
      >
        {progressing ? (
          <div className="p-24">
            <p className="text-[#1ffea9]">Loading...</p>
          </div>
        ) : (
          <div className="text-center px-8">
            <GiConfirmed className="mx-auto text-[#1ffea9] text-2xl" />
            <div className="my-4">
              <h3 className="text-lg font-black my-5 text-[#1ffea9]">
                {messages.confirmation}
              </h3>
            </div>
            <div className="flex flex-col gap-2 items-center">
              <div className="flex flex-col items-center">
                <div className="flex flex-col gap-2 ">
                  <p className="text-[#1ffea9]">{messages.transaction_info}</p>
                  <div className="flex flex-col items-start gap-1">
                    <span className="text-sm text-[#1ffea9]">Tomans:</span>
                    <input
                      className="p-1 bg-black border-1 
                      border-[#1ffea9] text-[#1ffea9]
                       focus:border-[#1ffea9]
                       focus:outline-none"
                    />
                  </div>
                  <div className="flex flex-col items-start gap-1">
                    <span className="text-sm text-[#1ffea9]">Dollars:</span>
                    <input
                      className="p-1 bg-black border-1 
                      border-[#1ffea9] text-[#1ffea9]
                       focus:border-[#1ffea9]
                       focus:outline-none"
                    />
                  </div>
                  <div className="flex">
                    <p className="text-[#1ffea9] text-sm">
                      {" "}
                      {messages.payment_rials_to_dollars} :
                    </p>
                    <p className="text-white ms-2 text-sm">37 $</p>
                  </div>
                  <div className="flex flex-col items-start gap-1 mt-8">
                    <span className="text-sm text-[#1ffea9]">
                      {messages.transaction_id}:
                    </span>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="p-1 bg-black border-1 
                      border-[#1ffea9] text-[#1ffea9]
                       focus:border-[#1ffea9]
                       focus:outline-none"
                    />
                  </div>
                  <div className="flex flex-col items-start gap-1">
                    <span className="text-sm text-[#1ffea9]">
                      {messages.last_4_digits}:
                    </span>
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      className="p-1 bg-black border-1 
                      border-[#1ffea9] text-[#1ffea9]
                       focus:outline-none"
                    />
                    {/* <div className="flex items-center">
                        <span className="text-red-500 text-3xl items-center">
                          *
                        </span>
                        <p className="text-white text-[10px] ms-2">
                          Please fill one of above fields
                        </p>
                      </div> */}
                    <p className="text-white text-[10px] place-content-baseline">
                      <span className="text-red-500 text-2xl align-middle">
                        *
                      </span>{" "}
                      {messages.rials_fill_warning}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex gap-4 mt-4">
              <button
                className="bg-[#1ffea9] py-1 text-black w-full"
                onClick={async () => {
                  // try {
                  //   if (requestedConfig === "" || requestedConfigAppId === 0) {
                  //     throw new Error("Necessary fields are empty");
                  //   }
                  //   const result = await saveAppConfig(
                  //     requestedConfigAppId,
                  //     requestedConfig
                  //   );
                  //   setCurrentToast({
                  //     isToasted: true,
                  //     text: result,
                  //     color: "[#1ffea9]",
                  //   });
                  // } catch (error) {
                  //   setCurrentToast({
                  //     isToasted: true,
                  //     text: error.message,
                  //     color: "red-500",
                  //   });
                  // }
                  // //reset all values
                  // setRequestedConfig("");
                  // setRequestedConfigAppId(0);
                  // setProgressing(false);
                  // fetchApplications();
                  // setAppConfigModalOpen(false);
                }}
              >
                {messages.payment_pay_button}
              </button>
              <button
                className="bg-red-500 py-1 text-black w-full"
                onClick={() => {
                  setRialTransactionEditModal(false);
                  setProgressing(false);
                }}
              >
                {messages.close_button}
              </button>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default Transactions;
