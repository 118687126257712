import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { SiShopware } from "react-icons/si";
import { MdOutlineCancel } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import { links } from "../data/dummy";
import { useStatContext } from "../contexts/ContextProvider";
import { GiTeamIdea } from "react-icons/gi";
import { useLocation } from "react-router-dom";

const Sidebar = () => {
  const { activeMenu, setActiveMenu, screenSize, currentColor, messages } =
    useStatContext();

  const [elementHovered, setElementHovered] = useState("");
  const location = useLocation();
  const currentRouteName = location.pathname.substring(1);

  const handleCloseSideBar = () => {
    if (activeMenu && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const activeLink =
    "flex items-center gap-5 relative ps-4 pt-3 pb-2.5 bg-[#1ffea9] text-black text-md m-2";
  const normalLink =
    "flex items-center gap-5 relative ps-4 pt-3 pb-2.5 text-md text-[#1ffea9] dark:text-gray-200 dark:hover:text-black m-2 hover:bg-[#1ffea9] hover:text-black";

  return (
    <div
      className="ms-3 h-screen
  md:overflow-hidden overflow-auto
  md:hover:overflow-auto pb-10"
    >
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link
              to="/"
              onClick={() => {
                {
                  handleCloseSideBar();
                }
              }}
              dir="ltr"
              className="items-center gap-3 ms-3
            mt-4 flex text-xl font-extrabold
            tracking-tight dark:text-white
            text-white"
            >
              <GiTeamIdea className="text-[#1ffea9]" />{" "}
              <span className="font-network">GreenNet</span>
              <span className="font-network font-thin text-sm">UZ Manager</span>
            </Link>
            <TooltipComponent content="Menu" position="BottomCenter">
              <button
                type="button"
                onClick={() => {
                  setActiveMenu((prevActiveMenu) => !prevActiveMenu);
                }}
                className="text-xl text-white rounded-full p-3 mt-4 block
              md:hidden"
              >
                <MdOutlineCancel />
              </button>
            </TooltipComponent>
          </div>
          <div className="mt-10">
            {links.map((item) => (
              <div key={item.title}>
                <p
                  className="text-[#1ffea9] m-3
            mt-4 uppercase"
                >
                  {messages[item.title]}
                </p>
                {item.links.map((link) => {
                  return (
                    <NavLink
                      key={link.name}
                      // onMouseEnter={() => {
                      //   setElementHovered(link.name);
                      // }}
                      // onMouseLeave={() => {
                      //   setElementHovered("");
                      // }}
                      to={`/${link.name}`}
                      onClick={() => {
                        handleCloseSideBar();
                      }}
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? "#1ffea9" : "",
                      })}
                      // className={normalLink}
                      className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                      }
                    >
                      {link.icon}
                      <span className="capitalize">
                        {messages[link.name.replace("-", "_")]}
                      </span>
                      {/* <div
                        className={`${
                          elementHovered === link.name ||
                          currentRouteName === link.name
                            ? "visible"
                            : "invisible"
                        }`}
                      >
                        <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
                        <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
                        <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
                        <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
                      </div> */}
                    </NavLink>
                  );
                })}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
