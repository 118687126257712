import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { Navbar, Footer, Sidebar, ThemeSettings } from "./components";
import {
  Ecommerce,
  Orders,
  Calendar,
  Employees,
  Stacked,
  Pyramid,
  Customers,
  Kanban,
  Area,
  Bar,
  Pie,
  Financial,
  ColorPicker,
  ColorMapping,
  Editor,
  Line,
  Overview,
  ServersStatus,
  ServersData,
  Groups,
  Applications,
  Announcements,
  TokenGenerate,
  TokenInfo,
  TokenExpires,
  TokenManagement,
  Devices,
  Wallet,
  Settings,
  Transactions,
} from "./pages";

import { useStatContext } from "./contexts/ContextProvider";
import "./App.css";
import Login from "./pages/Login";
import PrivateRoute from "./components/PrivateRoute";

const App = () => {
  const {
    activeMenu,
    themeSettings,
    setThemeSettings,
    currentColor,
    currentMode,
    toast,
    token,
    locale,
  } = useStatContext();

  if (locale === "fa") {
    document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  } else {
    document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
  }

  return (
    <div
      className={`${currentMode === "Dark" ? "dark" : ""} ${
        locale === "en" ? "font-network" : "font-vazir"
      }`}
    >
      {!token ? (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoute />}>
            {/* dashboard  */}
            <Route path="/" element={<Overview />} />
            <Route path="/overview" element={<Overview />} />

            {/* financial  */}
            <Route path="/transactions" element={<Transactions />} />

            {/* general  */}
            <Route path="/settings" element={<Settings />} />
          </Route>
          <Route path="*" element={404} />
        </Routes>
      ) : (
        <div className="flex relative dark:bg-main-dark-bg">
          <div className="fixed end-4 bottom-4" style={{ zIndex: "1000" }}>
            <TooltipComponent content="Settings" position="Top">
              <button
                type="button"
                onClick={() => setThemeSettings(true)}
                className="text-3xl 
          p-3 
          hover:drop-shadow-xl
          hover:bg-light-gray
          hidden
          text-white"
                style={{ background: currentColor, borderRadius: "50%" }}
              >
                <FiSettings />
              </button>
            </TooltipComponent>
          </div>
          {activeMenu ? (
            <div
              className="w-72 fixed sidebar
      dark:bg-secondary-dark-bg
      bg-black"
            >
              <Sidebar />
            </div>
          ) : (
            <div
              className="w-0
        dark:bg-secondary-dark-bg"
            >
              <Sidebar />
            </div>
          )}
          <div
            style={{ zIndex: "1000" }}
            className={`fixed flex h-10 
            p-2
          end-2 top-12
          bg-black
          border-${toast.color}
          border-1
          transition-all 
          ${
            toast.isToasted
              ? "translate-x-0 scale-100 opacity-100"
              : "scale-100 translate-x-10 opacity-0"
          }
          text-white justify-center items-center`}
          >
            <p className={`text-sm text-${toast.color}`}>{toast.text}</p>
          </div>

          <div
            style={{ zIndex: "0" }}
            className={`dark:bg-main-dark-bg bg-black
        min-h-screen w-full 
        ${activeMenu ? "md:ms-72" : "flex-2"}`}
          >
            <div
              className="static 
              bg-black
            dark:bg-main-dark-bg
        navbar w-full"
            >
              <Navbar />
            </div>
            <div>
              {themeSettings && <ThemeSettings />}

              <Routes>
                <Route path="/login" element={<Login />} />
                {/* dashboard  */}
                <Route path="/" element={<Overview />} />
                <Route path="/overview" element={<Overview />} />
                <Route path="/devices" element={<Devices />} />

                {/* financial  */}
                <Route path="/transactions" element={<Transactions />} />

                {/* general  */}
                <Route path="/settings" element={<Settings />} />
                <Route path="*" element={404} />
              </Routes>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
