import React, { useEffect, useState } from "react";
import {
  BsClock,
  BsCpuFill,
  BsCurrencyDollar,
  BsMemory,
  BsPower,
  BsTelephoneInboundFill,
} from "react-icons/bs";
import { GoDotFill } from "react-icons/go";

import { Stacked, Pie, Button, SparkLine, Header } from "../components";
import {
  earningData,
  SparklineAreaData,
  ecomPieChartData,
} from "../data/dummy";
import { useStatContext } from "../contexts/ContextProvider";
import {
  BiAccessibility,
  BiCalendar,
  BiCheck,
  BiDollar,
  BiDownload,
  BiMemoryCard,
  BiPause,
  BiPlay,
  BiRefresh,
  BiUpload,
  BiWindows,
} from "react-icons/bi";
import {
  GrClose,
  GrConfigure,
  GrMemory,
  GrStatusInfo,
  GrStorage,
  GrVirtualMachine,
} from "react-icons/gr";
import { SiSpeedtest } from "react-icons/si";
import { FiCpu } from "react-icons/fi";
import { MdMemory, MdPending, MdPendingActions } from "react-icons/md";
import { TiTicket } from "react-icons/ti";
import Modal from "../components/Modal";
import PlanCard from "../components/PlanCard";
import { GiOpenGate } from "react-icons/gi";
import {
  fetchAllCryptoTransactions,
  fetchManagerAllRialTransactions,
} from "../utils/HttpUtils";

const Overview = () => {
  const { fetchOverview, messages, setCurrentToast } = useStatContext();
  const [progressing, setProgressing] = useState(false);
  const [renewModalOpen, setRenewModalOpen] = useState(false);
  const [rialTransactions, setRialtransactions] = useState([]);
  const [cryptoTransactions, setCryptotransactions] = useState([]);

  const fetchManagerAllTransactionsFunc = async () => {
    try {
      let result = await fetchManagerAllRialTransactions();
      setRialtransactions(result);
    } catch (error) {
      setCurrentToast({
        isToasted: true,
        text: error.message,
        color: "red-500",
      });
    }
  };

  const fetchAllCryptoTransactionsFunc = async () => {
    try {
      let result = await fetchAllCryptoTransactions();
      // console.log(cryptotransactions);
      setCryptotransactions(result);
    } catch (error) {
      setCurrentToast({
        isToasted: true,
        text: error.message,
        color: "red-500",
      });
    }
  };

  useEffect(() => {
    fetchManagerAllTransactionsFunc();
    fetchAllCryptoTransactionsFunc();
    // fetchOverals();

    // setInterval(fetchOverals, 3000);
    return () => {
      // clearInterval(fetchOverals);
    };
  }, []);

  let mainTransactions = [];

  if (rialTransactions.length > 0) {
    for (const obj of rialTransactions) {
      mainTransactions.push(obj);
    }
  }

  if (cryptoTransactions.length > 0) {
    for (const obj of cryptoTransactions) {
      mainTransactions.push(obj);
    }
  }

  let pendingTransactions = [];
  let successTransactions = [];
  let rejectedTransactions = [];

  if (mainTransactions && mainTransactions.length > 0) {
    for (let i = 0; i < mainTransactions.length; i++) {
      let tx = mainTransactions[i];
      if (tx.status === 1) {
        successTransactions.push(tx);
      } else if (tx.status === 2) {
        rejectedTransactions.push(tx);
      } else {
        pendingTransactions.push(tx);
      }
    }
  }

  return (
    <>
      <div
        className="relative mt-12 flex-col justify-center items-center
    m-10 p-5
    bg-black"
      >
        <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
        <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
        <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
        <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
        <Header category={messages.zone} title={messages.overview} />
        {mainTransactions && mainTransactions.length > 0 ? (
          <div
            className="flex flex-col gap-2 justify-start
      my-10 mx-8"
          >
            <h1 className="border-b-1 text-white">
              {messages.pending_transactions}
            </h1>
            <div className="flex flex-col justify-start gap-5 p-5">
              <div className="flex items-center flex-wrap">
                <MdPendingActions className="text-orange-400 text-xl" />
                <span className="ms-2  text-orange-400 font-semibold">:</span>
                <span className="ms-2 text-black bg-orange-400 px-2">
                  {pendingTransactions.length} {messages.overview_transactions}
                </span>
              </div>
            </div>
            <h1 className="border-b-1 text-white">
              {messages.accepted_transactions}
            </h1>
            <div className="flex flex-col justify-start gap-5 p-5">
              <div className="flex items-center flex-wrap">
                <BiCheck className="text-[#1ffea9] text-xl" />
                <span className="ms-2  text-[#1ffea9] font-semibold">:</span>
                <span className="ms-2 text-black bg-[#1ffea9] px-2">
                  {successTransactions.length} {messages.overview_transactions}
                </span>
              </div>
            </div>
            <h1 className="border-b-1 text-white">
              {messages.rejected_transactions}
            </h1>
            <div className="flex flex-col justify-start gap-5 p-5">
              <div className="flex items-center flex-wrap">
                <GrClose className="text-red-500 text-xl" />
                <span className="ms-2  text-red-500 font-semibold">:</span>
                <span className="ms-2 text-black bg-red-500 px-2">
                  {rejectedTransactions.length} {messages.overview_transactions}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center">
            <div className="loader"></div>
          </div>
        )}
      </div>
    </>
  );
};

export default Overview;
