import React, { useEffect, useState } from "react";
import {
  BsAndroid,
  BsClock,
  BsCpuFill,
  BsCurrencyDollar,
  BsMemory,
  BsTelephoneInboundFill,
} from "react-icons/bs";
import { GoDotFill } from "react-icons/go";

import { Stacked, Pie, Button, SparkLine, Header } from "../components";
import {
  earningData,
  SparklineAreaData,
  ecomPieChartData,
} from "../data/dummy";
import { useStatContext } from "../contexts/ContextProvider";
import {
  BiDownload,
  BiMemoryCard,
  BiPause,
  BiPlay,
  BiUpload,
} from "react-icons/bi";
import { GrMemory, GrStatusInfo, GrStorage } from "react-icons/gr";
import { SiSpeedtest } from "react-icons/si";
import { FiCpu } from "react-icons/fi";
import { MdDelete, MdMemory } from "react-icons/md";
import { TiTicket } from "react-icons/ti";

const Devices = () => {
  const { currentColor, fetchOverview, messages } = useStatContext();
  const [overal, setOveral] = useState({
    CPU: "0",
    MEMORY: "0",
    RX: "0.0",
    RXBW: "0.0",
    TX: "0",
    TXBW: "0.0",
  });
  useEffect(() => {
    const fetchOverals = async () => {
      try {
        const resp = await fetchOverview();
        setOveral((prev) => resp);
      } catch (error) {
        console.log(error);
      }
    };
    // fetchOverals();

    // setInterval(fetchOverals, 3000);
    return () => {
      // clearInterval(fetchOverals);
    };
  }, []);

  return (
    <div
      className="relative mt-12 flex-col justify-center items-center
    m-10 p-5
    bg-black"
    >
      <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
      <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
      <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
      <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
      <Header category={messages.zone} title={messages.devices} />
      <div
        className="flex flex-col gap-2 justify-start
      my-10 mx-8"
      >
        <div
          key={"1"}
          className="flex justify-between items-center
          bg-[#1ffea9] text-black gap-5 border-1 border-transparent
          hover:bg-transparent hover:text-[#1ffea9]
          hover:border-1 hover:border-[#1ffea9]  
          font-body md:w-2/3 p-2"
        >
          <div className="flex items-center gap-5 ">
            <BsAndroid />
            <div className="flex flex-col gap-1">
              <span className=" text-sm">
                ghost73416613-e0d5-472e-a073-45fb412d43d5
              </span>
              <span className=" text-[11px]">bba217fe953b9ca3</span>
            </div>
          </div>

          <button
            onClick={() => {
              //   setQuestionableAction(() => () => {
              //     return deleteTokenDevice(
              //       tokenObject.token,
              //       device.userId
              //     );
              //   });
              //   setModalIcon(
              //     <MdDelete className="mx-auto text-red-500 text-3xl" />
              //   );
              //   setModalDesc(
              //     "Are you sure you want to delete this device?"
              //   );
              //   setModalOpen(true);
            }}
          >
            <MdDelete className=" place-items-end " />
          </button>
        </div>
        <div
          key={"1"}
          className="flex justify-between items-center
          bg-[#1ffea9] text-black gap-5 border-1 border-transparent
          hover:bg-transparent hover:text-[#1ffea9]
          hover:border-1 hover:border-[#1ffea9]  
          font-body md:w-2/3 p-2"
        >
          <div className="flex items-center gap-5 ">
            <BsAndroid />
            <div className="flex flex-col gap-1">
              <span className=" text-sm">
                ghost73416613-e0d5-472e-a073-45fb412d43d5
              </span>
              <span className=" text-[11px]">bba217fe953b9ca3</span>
            </div>
          </div>

          <button
            onClick={() => {
              //   setQuestionableAction(() => () => {
              //     return deleteTokenDevice(
              //       tokenObject.token,
              //       device.userId
              //     );
              //   });
              //   setModalIcon(
              //     <MdDelete className="mx-auto text-red-500 text-3xl" />
              //   );
              //   setModalDesc(
              //     "Are you sure you want to delete this device?"
              //   );
              //   setModalOpen(true);
            }}
          >
            <MdDelete className=" place-items-end " />
          </button>
        </div>
        <div
          key={"1"}
          className="flex justify-between items-center
                      bg-[#1ffea9] text-black gap-5 border-1 border-transparent
                      hover:bg-transparent hover:text-[#1ffea9]
                      hover:border-1 hover:border-[#1ffea9]  
                      font-body md:w-2/3 p-2"
        >
          <div className="flex items-center gap-5 ">
            <BsAndroid />
            <div className="flex flex-col gap-1">
              <span className=" text-sm">
                ghost73416613-e0d5-472e-a073-45fb412d43d5
              </span>
              <span className=" text-[11px]">bba217fe953b9ca3</span>
            </div>
          </div>

          <button
            onClick={() => {
              //   setQuestionableAction(() => () => {
              //     return deleteTokenDevice(
              //       tokenObject.token,
              //       device.userId
              //     );
              //   });
              //   setModalIcon(
              //     <MdDelete className="mx-auto text-red-500 text-3xl" />
              //   );
              //   setModalDesc(
              //     "Are you sure you want to delete this device?"
              //   );
              //   setModalOpen(true);
            }}
          >
            <MdDelete className=" place-items-end " />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Devices;
