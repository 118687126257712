import React, { useEffect, useState } from "react";
import {
  BsClock,
  BsCpuFill,
  BsCurrencyDollar,
  BsMemory,
  BsTelephoneInboundFill,
} from "react-icons/bs";
import { GoDotFill } from "react-icons/go";

import { Stacked, Pie, Button, SparkLine, Header } from "../components";
import {
  earningData,
  SparklineAreaData,
  ecomPieChartData,
} from "../data/dummy";
import { useStatContext } from "../contexts/ContextProvider";
import {
  BiDownload,
  BiMemoryCard,
  BiPause,
  BiPlay,
  BiUpload,
} from "react-icons/bi";
import { GrMemory, GrStatusInfo, GrStorage } from "react-icons/gr";
import { SiSpeedtest } from "react-icons/si";
import { FiCpu } from "react-icons/fi";
import { MdMemory } from "react-icons/md";
import { TiTicket } from "react-icons/ti";

const Settings = () => {
  const { currentColor, fetchOverview, messages } = useStatContext();
  const [overal, setOveral] = useState({
    CPU: "0",
    MEMORY: "0",
    RX: "0.0",
    RXBW: "0.0",
    TX: "0",
    TXBW: "0.0",
  });
  useEffect(() => {
    const fetchOverals = async () => {
      try {
        const resp = await fetchOverview();
        setOveral((prev) => resp);
      } catch (error) {
        console.log(error);
      }
    };
    // fetchOverals();

    // setInterval(fetchOverals, 3000);
    return () => {
      // clearInterval(fetchOverals);
    };
  }, []);

  return (
    <div
      className="relative mt-12 flex-col justify-center items-center
    m-10 p-5
    bg-black"
    >
      <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
      <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
      <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
      <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
      <Header category={messages.general} title={messages.settings} />
      <div
        className="flex flex-col gap-2 justify-start
      my-6 mx-4"
      >
        <h1 className="border-b-1 text-white">{messages.change_password}</h1>
        <div className="flex flex-col justify-start gap-5 w-2/3">
          <div className="flex justify-between items-center ">
            <span className=" text-white font-semibold">
              {messages.current_password}:
            </span>
            <input
              className="ms-2 p-1"
              placeholder={messages.enter_current_password}
            />
          </div>
          <div className="flex justify-between items-center">
            <span className=" text-white font-semibold">
              {messages.new_password}:
            </span>
            <input
              className="ms-2 p-1"
              placeholder={messages.enter_new_password}
            />
          </div>
        </div>
        <button
          className="self-end p-2 border-1
         bg-[#1ffea9] border-[#1ffea9] hover:bg-black hover:border-[#1ffea9] hover:text-[#1ffea9]"
        >
          {messages.save}
        </button>
      </div>
    </div>
  );
};

export default Settings;
