const apiUrl = "https://userzoneapi.greennetapp.com/";
// const apiUrl = "http://localhost:1443/";

export const fetchManagerTransactions = async () => {
  try {
    const token = localStorage.getItem("site");
    const response = await fetch(apiUrl + "info/manager-rial-transactions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
    });

    if (response.ok) {
      const res = await response.json();
      return res.Obj;
    }
    const errBody = await response.json();
    throw new Error(errBody.Message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const fetchPendingCryptoTransactions = async () => {
  try {
    const token = localStorage.getItem("site");
    const response = await fetch(apiUrl + "info/crypto-transactions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
    });

    if (response.ok) {
      const res = await response.json();
      return res.Obj;
    }
    const errBody = await response.json();
    throw new Error(errBody.Message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const validateTransaction = async (transactionId, userId, status) => {
  try {
    let data = {
      transaction_id: transactionId,
      user_id: userId,
      status: status,
    };
    const token = localStorage.getItem("site");
    const response = await fetch(apiUrl + "manage/validate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const res = await response.json();
      return res.Message;
    }
    const errBody = await response.json();
    throw new Error(errBody.Message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const validateFirstUserTransaction = async (
  transactionId,
  userId,
  status
) => {
  try {
    let data = {
      transaction_id: transactionId,
      user_id: userId,
      status: status,
    };
    const token = localStorage.getItem("site");
    const response = await fetch(apiUrl + "manage/validate-first-rial", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const res = await response.json();
      return res.Message;
    }
    const errBody = await response.json();
    throw new Error(errBody.Message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const validateCryptoTransaction = async (
  transactionId,
  userId,
  status
) => {
  try {
    let data = {
      transaction_id: transactionId,
      user_id: userId,
      status: status,
    };
    const token = localStorage.getItem("site");
    const response = await fetch(apiUrl + "manage/validate-crypto", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const res = await response.json();
      return res.Message;
    }
    const errBody = await response.json();
    throw new Error(errBody.Message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const fetchManagerAllRialTransactions = async () => {
  try {
    const token = localStorage.getItem("site");
    const response = await fetch(
      apiUrl + "info/manager-all-rial-transactions",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Cache-Control": "no-cache",
          Authorization: "Bearer " + token,
        },
      }
    );

    if (response.ok) {
      const res = await response.json();
      return res.Obj;
    }
    const errBody = await response.json();
    throw new Error(errBody.Message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const fetchAllCryptoTransactions = async () => {
  try {
    const token = localStorage.getItem("site");
    const response = await fetch(apiUrl + "info/all-crypto-transactions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
    });

    if (response.ok) {
      const res = await response.json();
      return res.Obj;
    }
    const errBody = await response.json();
    throw new Error(errBody.Message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const sandy = async () => {
  try {
    const token = localStorage.getItem("site");
    const response = await fetch(apiUrl + "manage/sandy", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
    });

    if (response.ok) {
      const res = await response.json();
      return res.Message;
    }
    const errBody = await response.json();
    throw new Error(errBody.Message);
  } catch (err) {
    throw new Error(err.message);
  }
};
