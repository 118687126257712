import React, { useEffect, useState } from "react";
import { useStatContext } from "../contexts/ContextProvider";

const Login = () => {
  const { loginAction } = useStatContext();
  const [input, setInput] = useState({
    username: "",
    password: "",
  });

  const [invalid, setInvalid] = useState({
    invalidUsername: false,
    invalidPassword: false,
    message: "",
  });

  const handleSubmitEvent = async (e) => {
    e.preventDefault();
    const resetInvalid = {
      invalidUsername: false,
      invalidPassword: false,
      message: "",
    };
    setInvalid((prev) => resetInvalid);
    if (
      input.username !== "" &&
      input.password !== "" &&
      input.username.length > 5 &&
      input.password.length > 5
    ) {
      //dispatch action from hooks
      try {
        await loginAction(input);
      } catch (e) {
        const newInvalid = {
          invalidUsername: true,
          invalidPassword: true,
          message: e.message,
        };
        setInvalid((prev) => newInvalid);
      }

      return;
    }
    if (input.username === "" || input.username.length < 5) {
      const newInvalid = {
        invalidUsername: true,
        invalidPassword: false,
        message: "Invalid Username",
      };
      setInvalid((prev) => newInvalid);
    } else if (input.password === "" || input.password.length < 5) {
      const newInvalid = {
        invalidUsername: false,
        invalidPassword: true,
        message: "Invalid Password",
      };
      setInvalid((prev) => newInvalid);
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div
      className="flex h-screen
    bg-black
     justify-center items-center
      font-network"
      dir="ltr"
    >
      <div className="w-full max-w-xs">
        <form
          onSubmit={handleSubmitEvent}
          className="border-[#1ffea9] border-1 p-5 mb-4"
        >
          <div className="mb-4">
            <label
              className="block text-white text-sm
             font-bold mb-2"
              htmlFor="user-username"
            >
              Username
            </label>
            <input
              className={`appearance-none 
              border w-full 
              py-2 px-3
              ${invalid.invalidUsername ? "border-red-500" : ""} 
               text-white
              leading-tight 
              bg-black
              focus:outline-none focus:shadow-outline`}
              id="user-username"
              name="username"
              placeholder="name.lastname"
              aria-describedby="user-username"
              onChange={handleInput}
              aria-invalid="false"
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-white text-sm font-bold mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className={`appearance-none border
             ${invalid.invalidPassword ? "border-red-500" : ""} w-full py-2 px-3
            text-white mb-3
              leading-tight focus:outline-none
              bg-black
              focus:shadow-outline`}
              id="password"
              name="password"
              type="password"
              onChange={handleInput}
              placeholder="******************"
            />
            {(invalid.invalidUsername || invalid.invalidPassword) && (
              <p className="text-red-500 text-xs italic">{invalid.message}</p>
            )}
          </div>
          <div className="flex items-center justify-center">
            <button
              className="border-1 border-[#1ffea9] text-[#1ffea9] hover:bg-[#1ffea9]
              hover:text-black
               font-bold py-2 px-4 
              focus:outline-none focus:shadow-outline
              btn-submit"
              type="submit"
            >
              Sign In
            </button>
          </div>
        </form>
        <p
          className="text-center
         text-[#1ffea9] font-semibold text-xs"
        >
          &copy;2024 Anonymith. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Login;
